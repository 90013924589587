import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Therapist = ({ therapists, products }) => {

    const [filterVal, setFilterVal] = useState("")
    const [price, setPrice] = useState("")
    useEffect(() => {
        setTimeout(() => {
            setPid(products[0]?.id)
            setPrice(products[0]?.price)
            setFilterVal(mapProductData(products[0]?.name))
        }, 1000);
    }, [])

    const Obj = {
        "Occupation Therapist": "Occupational Therapy",
        "Counsellor": "Counselling",
        "Speech Therapist": "Speech Therapy",
        "Special Educator": "Special Education",
        "Consultation": "Consultation"
    }
    const mapProductData = (val) => {
        if (Obj['Occupation Therapist'] === val)
            return 'Occupation Therapist'
        if (Obj['Counsellor'] === val)
            return 'Counsellor'
        if (Obj['Speech Therapist'] === val)
            return 'Speech Therapist'
        if (Obj['Special Educator'] === val)
            return 'Special Educator'
        if (Obj['Consultation'] === val)
            return 'Consultation'
    }

    const [pid, setPid] = useState("")
    const handleClickProduct = (e, product) => {
        var productsDom = document?.querySelectorAll(".therapist-tab-link")
        productsDom?.forEach((ele) => {
            if (e.target.id == ele.id) {
                ele.className = "therapist-tab-link active"
            } else {
                ele.className = "therapist-tab-link"
            }
        })
        setPid(product?.id)
        setPrice(product?.price)
        setFilterVal(mapProductData(product?.name))
    }

    // console.log(price);
    // const handleNavigate = (id) => {
    //     history.push({ pathname: `/BookTherapist?id=${id}`, state: { val: price } })
    // }

    return (
        <div className="therapist">
            <div className="section-parallax">
                <div className="parallax">
                    <div className="container">
                        <div className="col-md-6 mx-auto">
                            <h3 className="caption font-weight-bold font-25 mb-2 text-center">Our Therapist</h3>
                            <p className="text-center"><Link to="/" className="text-white"><u>Home</u></Link> / <span className="text-white">Therapist</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-gray">
                <div className="container">


                    {/* tab start */}
                    <div className="therapist-tab-outer">
                        {products && products?.length > 0 && products?.map((prod, i) => {
                            return <a key={i} className={`therapist-tab-link ${i == 0 && "active"}`} id={prod?.id} onClick={(e) => handleClickProduct(e, prod)}>{prod?.name}</a>
                        })}
                        {/* <a href="" className="therapist-tab-link active">Psychologist</a>
                        <a href="" className="therapist-tab-link">Psychologist</a>
                        <a href="" className="therapist-tab-link">Psychologist</a>
                        <a href="" className="therapist-tab-link">Psychologist</a>
                        <a href="" className="therapist-tab-link">Psychologist</a> */}
                    </div>
                    {/* tab End */}


                    <div className="row">

                        {therapists?.length > 0 && filterVal !== "" && therapists?.filter((item) => item.speciality == filterVal)?.map((therapist, i) => {
                            return <div className="col-md-4" key={i}>
                                <div className="grid gallery">
                                    <div className="">
                                        <img src={therapist?.avatar?.length > 20 ? `data:image/jpeg;base64,${therapist?.avatar}` : "img/therapist/10.jpg"} className="therapist-img" />
                                        <h5 className="card-title mb-3">
                                            <span className="font-15 font-weight-bold" title=""> {therapist?.name} </span>
                                        </h5>
                                        <div className="pb-4">
                                            <div className="text-muted font-14">{therapist?.speciality}</div>
                                            <div className="text-muted font-weight-bold mt-3 font-14">{`₹${price}/Session`}</div>
                                        </div>
                                        {/* <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link> */}
                                        <Link to={`/BookTherapist?id=${therapist?.id}&pid=${pid}`} className="btn btn-theme-color btn-block">Book Appointment</Link>
                                    </div>
                                </div>
                            </div>
                            //  <div className="col-md-4" key={therapist?.id}>
                            //     <div className="grid gallery">
                            //         <div className="">
                            //             <img src={therapist?.avatar?.length > 20 ? `data:image/jpeg;base64,${therapist?.avatar}` : "img/therapist/1.jpeg"} className="therapist-img" />
                            //             <h5 className="card-title mb-3">
                            //                 <span className="font-15 font-weight-bold"> {therapist?.name} </span>
                            //             </h5>
                            //             <div className="pb-4">
                            //                 <div className="text-muted font-14">{therapist?.speciality}</div>
                            //                 <div className="text-muted font-weight-bold mt-3 font-14">{`₹${price}/Session`}</div>
                            //             </div>
                            //             {/* <Link to={{ pathname: `/BookTherapist?id=${therapist?.id}`, state: { val: price } }} className="btn btn-theme-color btn-block">Book Appointment</Link> */}
                            //             <Link to={`/BookTherapist?id=${therapist?.id}&pid=${pid}`} className="btn btn-theme-color btn-block">Book Appointment</Link>
                            //         </div>
                            //     </div>
                            // </div>
                        })}
                        {/* <div className="col-md-4">
                            <div className="grid gallery">
                                <div className="">
                                    <img src="img/therapist/10.jpg" className="therapist-img" />
                                    <h5 className="card-title mb-3">
                                        <span className="font-15 font-weight-bold" title=""> Pragati Goyal </span>
                                    </h5>
                                    <div className="pb-4">
                                        <div className="text-muted font-14">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</div>
                                        <div className="text-muted font-weight-bold mt-3 font-14">₹1200/Session</div>
                                    </div>
                                    <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="grid gallery">
                                <div className="">
                                    <img src="img/therapist/9.jpg" className="therapist-img" />
                                    <h5 className="card-title mb-3">
                                        <span className="font-15 font-weight-bold" title=""> Pragati Goyal </span>
                                    </h5>
                                    <div className="pb-4">
                                        <div className="text-muted font-14">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</div>
                                        <div className="text-muted font-weight-bold mt-3 font-14">₹1200/Session</div>
                                    </div>
                                    <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="grid gallery">
                                <div className="">
                                    <img src="img/therapist/8.jpg" className="therapist-img" />
                                    <h5 className="card-title mb-3">
                                        <span className="font-15 font-weight-bold" title=""> Pragati Goyal </span>
                                    </h5>
                                    <div className="pb-4">
                                        <div className="text-muted font-14">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</div>
                                        <div className="text-muted font-weight-bold mt-3 font-14">₹1200/Session</div>
                                    </div>
                                    <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="grid gallery">
                                <div className="">
                                    <img src="img/therapist/7.jpg" className="therapist-img" />
                                    <h5 className="card-title mb-3">
                                        <span className="font-15 font-weight-bold" title=""> Pragati Goyal </span>
                                    </h5>
                                    <div className="pb-4">
                                        <div className="text-muted font-14">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</div>
                                        <div className="text-muted font-weight-bold mt-3 font-14">₹1200/Session</div>
                                    </div>
                                    <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="grid gallery">
                                <div className="">
                                    <img src="img/therapist/6.jpg" className="therapist-img" />
                                    <h5 className="card-title mb-3">
                                        <span className="font-15 font-weight-bold" title=""> Pragati Goyal </span>
                                    </h5>
                                    <div className="pb-4">
                                        <div className="text-muted font-14">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</div>
                                        <div className="text-muted font-weight-bold mt-3 font-14">₹1200/Session</div>
                                    </div>
                                    <Link to="/BookTherapist" className="btn btn-theme-color btn-block">Book Appointment</Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>



            {/* <!--popup-START--> */}
            <div className="modal fade bd-example-modal-lg pr-0" id="therapist-appointment-popup" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="row no-gutters">
                            <div className="col-md-6 d-md-flex d-none">
                                <div className="logo-popup w-100 text-center px-4">
                                    <img src="img/logo.png" className="img-fluid" alt="logo" />
                                    <p className="mt-4 font-15 caption">
                                        Have a question? Just send a message and we’ll get back to you as quickly as possible.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex" style={{ flexDirection: "column" }}>
                                <div className="modal-header pt-3 pb-2 mb-3" style={{ zIndex: "10" }}>
                                    <h3 className="caption text-black font-weight-bolder font-20 text-left mb-2">
                                        <span className="login-content">Book An Appointment Now</span>
                                    </h3>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="row card-row-set">
                                    <div className="col-md-12">
                                        <form className="px-2" method="post" id="" name="formregister">
                                            <div className="form-group">
                                                <input type="text" name="registername" className="form-control style-input border" placeholder="Enter Full Name" />
                                            </div>

                                            <div className="form-group">
                                                <input type="text" name="registermobile" className="form-control style-input border" placeholder="Mobile No" />
                                            </div>

                                            <div className="form-group">
                                                <label className="small font-weight-bold mb-1">Select Date</label>
                                                <input type="date" name="" className="form-control style-input border" placeholder="select date" />
                                            </div>

                                            <div className="form-group">
                                                <label className="small font-weight-bold mb-1">Select Time</label>
                                                <input type="time" name="" className="form-control style-input border" placeholder="select time" />
                                            </div>
                                            <div className="form-group">
                                                <textarea type="text" name="" rows="4" className="form-control style-input border" placeholder="Your Message" style={{ height: "auto" }}></textarea>
                                            </div>

                                            <div className="form-group" id="registerdiv">
                                                <button type="submit" className="btn btn-black-color btn-block text-black style-input" name="">Proceed to Payment</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--popup-END--> */}





        </div>
    );
}

export default Therapist;