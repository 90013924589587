const Subscribe_newslatter = () => {
    return (
        <div>
            {/* Subscribe newslatter section start */}
            <div className="section-parallax" style={{ backgroundImage: "url(img/appointment.jpg)", backgroundSize: "contain" }}>
                <div className="parallax" style={{ background: "none" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <h3 className="caption font-weight-bold font-25 mb-3">Book Telephonic Appointment! <strong>Call Us</strong></h3>
                                <h1 className="mb-4"><a className="caption font-weight-bold font-3rem text-white" href="tel:+919810123889"><i className="fas fa-phone fa-rotate-90"></i> +91-9810123889 </a></h1>
                            </div>
                            <div className="col-md-5 text-md-right align-self-center">
                                <button className="btn btn-theme-color" type="button">Make an Appointment <i className="fas fa-paper-plane ml-2 fa-zoom-hover"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Subscribe newslatter section end */}
        </div>
    );
}

export default Subscribe_newslatter;