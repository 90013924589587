const Award = () => {
    return ( 
        <div> 
{/* awards start */} 
<div className="section-parallax" style={{backgroundImage: "url(img/experience.jpg)",backgroundSize: "contain"}}>
<div className="parallax py-4" style={{background: "none"}}>
        <div className="container"> 
            <div className="row mt-5 mb-4 animate__animated animate__fadeInUp" id="counter">
                <div className="col-md-3 align-self-center">
                <h3 className="caption font-weight-bold font-25 mb-4 text-white">Over a Decade of Experience</h3>
                </div>
                <div className="col-md-3">
                    <div className="text-decoration-none text-center bg-transparent shadow-none py-0">
                        <div className="">
                        <i className="fas fa-heartbeat font-2rem mb-3 text-theme-color2"></i>
                            <h5 className="card-title mb-0 font-2rem font-weight-bold text-white">
                                <span className="counter-value" data-count="60000"> 0 </span> <span>+</span> 
                            </h5>
                            <div className="py-2">
                                <div className="text-white font-18 font-weight-bold">Regional Reach</div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-decoration-none text-center bg-transparent shadow-none py-0">
                        <div className="">
                        <i className="fas fa-check-double font-2rem mb-3 text-theme-color2"></i>
                            <h5 className="card-title mb-0 font-2rem font-weight-bold text-white">
                                <span className="counter-value" data-count="30000"> 0 </span> <span>+</span> 
                            </h5>
                            <div className="py-2">
                                <div className="text-white font-18 font-weight-bold">National Reach</div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="text-decoration-none text-center bg-transparent shadow-none py-0">
                        <div className="">
                        <i className="fas fa-thumbs-up font-2rem mb-3 text-theme-color2"></i>
                            <h5 className="card-title mb-0 font-2rem font-weight-bold text-white">
                                <span className="counter-value" data-count="39"> 0 </span> <span>+ </span> <span>Countries</span>
                            </h5>
                            <div className="py-2">
                                <div className="text-white font-18 font-weight-bold">International Reach</div>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </div>
    </div>
{/* awards end */} 
        </div>
     );
}
 
export default Award;