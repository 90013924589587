import axios from "axios";
import { BASE_URL } from "../utils/api";
import { getToken } from "./helper";

const token = getToken()
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export async function displayRazorpay(payData) {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    const options = {
        key: "rzp_live_192AoNZXou0L94",
        amount: payData?.order?.amount.toString(),
        currency: "INR",
        name: "Mind Meadow",
        description: "It gets better...",
        // image: { logo },
        order_id: payData?.order?.id,
        handler: async function (response) {
            alert("Payment Successful with Payment ID:" + response.razorpay_payment_id)
            const data = {
                ProductId: payData?.productId,
                patientId: payData?.patientId,
                doctorId: payData?.docId,
                dates: payData?.dates || [],
                time: payData?.time,
                OrderId: payData?.order?.id,
                PaymentId: response.razorpay_payment_id,
                PaymentStatus: response.razorpay_payment_id ? true : false
            };
            const result = await axios.post(BASE_URL + "/api/appointment/createWithDoc", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                }
            },)
            // console.log("APPOINTMENT CREATED DATA::", result);
            if (result?.status) {
                let isConfirmed = confirm("Appointment created successfully. Check your E-mail for more details.")
                if (isConfirmed) {
                    window.location.replace("/")
                }

            }
        },
        prefill: {
            name: payData?.user?.name,
            email: payData?.user?.email,
            contact: payData?.user?.mobileNumber,
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
        alert(response.error.description)
    })
    paymentObject.open();
}
