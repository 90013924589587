// export const BASE_URL = "https://mindmeadow.herokuapp.com";
export const BASE_URL = "https://mindmeadow.techicious.store";


export const api = {
  auth: {
    login: "/api/admin/login",
    logout: "/api/admin/logout",
  },
  patients: {
    getPatients: "/api/admin/allkids",
    getPatientByUserId: "/api/admin/userkids?userId=",
  },
  therapists: {
    getTherapists: "/api/doctor/alldoctors",
    getTherapistById: "/api/doctor?id=",
  },
  parents:{
    getParents: "/api/admin/allusers",
  },
  services:{
    getServices: "/api/product/allproducts",
  }
};
