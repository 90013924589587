import { Link } from "react-router-dom";

 const Footer = () => {
    return ( 
        <div>
             {/* <!-- footer start --> */}
    {/* <img src="img/footer-round.png" className="img-fluid" style={{marginBottom: "-1px"}} alt="footer-img" /> */}
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-sm-4 p-3">
                    <img src="img/logo.png" width="150px" className="mb-4" alt="logo" />
                    <div className="footer-link mt-0">
                        <p className="">It gets better
                        </p>
                    </div>
                    <div>
                        <a href="/" target="_blank" className="pr-2 font-1rem"><i className="fab social-icon fa-instagram"></i></a>
                        <a href="/" target="_blank" className="pr-2 font-1rem"><i className="fab social-icon fa-facebook-square"></i></a>
                        <a href="/" target="_blank" className="pr-2 font-1rem"><i className="fab social-icon fa-twitter"></i></a>
                    </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2 p-3">
                    <div className="footer-link">
                        <ul className="pl-0">
                            <h5 className="font-weight-bold mb-3">Explore</h5>
                            {/* 
                            <a className="nav-link ml-0 font-14" href="#services"> Services</a>
                            <a className="nav-link ml-0 font-14" href="#aboutus"> About</a>
                            <a className="nav-link ml-0 font-14" href="#gallery"> Gallery</a>
                            <a className="nav-link ml-0 font-14" href="#blog"> Blog</a>
                            
                            */}
                            <li><a href="/">Home</a></li>
                            <li><a href="#aboutus">About Us</a></li>
                            <li><a href="#gallery">Gallery</a></li>
                            <li><a href="#services">Services</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-2 p-3">
                    <div className="footer-link">
                        <ul className="pl-0">
                            <h5 className="font-weight-bold mb-3">Resources</h5>
                            {/* <li><a href={} className="">Help</a></li> */}
                            <li><Link to="/Blogs" className="">Blog</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-3 p-3">
                    <div className="footer-link">
                        <ul className="pl-0">
                            <h5 className="font-weight-bold mb-3">Delhi Clinic Address</h5>
                            <li>
                                <a href="https://goo.gl/maps/BjdsgsfkpEeQhL5t8" target="_blank">

                                <p className="d-flex"><i  className="fas fa-map-marker-alt text-theme-color2 mt-1 mr-3"></i> CSP-2822, Tower 2, 8th Floor, DLF Capital Greens, Moti Nagar, New Delhi - 110015 </p>
                                </a>
                            </li>
                            <li> <a href="tel:+919810129089" className=""><i className="fas fa-phone text-theme-color2 mr-3"></i> +91 9810129089 </a>
                            </li>
                            <li> <a href="mailto:info@mindmeadow.in" className=""><i className="fas fa-envelope text-theme-color2 mr-3"></i> info@mindmeadow.in </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="footer-copyright">
        <div className="container">
            {/* <i className="far fa-copyright"></i> Copyrights 2022 <a href="" className="text-theme-color2">Mind Meadow </a>All Rights Reserved */}
            <i className="fa fa-power-off"></i> &nbsp; Powered by <a href="http://techicious.com" target="_blank" className="text-theme-color2">Techicious </a>
        </div>
    </div>
    {/* <!-- footer end --> */} 
        </div>
     );
}
 
export default Footer;