import OwlCarousel from "react-owl-carousel";

const Testimonials = () => {
    const owl_carousel_testimonial = { 
        margin: 20,
        loop: true,
        // navRewind: true,
        nav: true,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='fas fa-long-arrow-alt-left'></i>",
            "<i class='fas fa-long-arrow-alt-right'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 2
            }
        }
};
    return ( 
        <div> 
{/* Testimonails start */} 
<div className="section-white" style={{backgroundImage: "url(img/testimonial-bg2.jpg)" , backgroundSize: "cover",backgroundAttachment:"fixed"}}>
        <div className="">
            <div className="container">
            <div className="col-md-5 text-center mx-auto mb-md-5">
                        <p className="font-15 text-white font-weight-500 mb-0">TESTIMONIALS</p>
                        <h3 className="caption font-weight-bold font-2rem mb-3 text-white">What Parents Say</h3>
                        <p className="font-15 text-white">We appreciate your feedback and assure you of our best services always.</p>
                    </div>
                <div className="row"> 
                    <div className="col-md-12">
                    <OwlCarousel className="owl-carousel owl-carousel-testimonial owl-theme owl-loaded owl-drag animate__animated animate__fadeInUp" {...owl_carousel_testimonial}>
                            <div className="item">
                                <div className="grid grid2">
                                    <img src="img/testimonial/woman.png" className="client-profile" />
                                    <i className="fas fa-quote-right font-2rem text-theme-color2 mb-0"></i>
                                    <figure className="font-14 text-black mb-4">
                                    I feel a child psychologist must gain the trust of a parent first. Dr.Imran scrutinizes your concerns patiently and magnanimously. He must be your first go-to- person if facing challenges in your cherub's grooming.He has an amazing grip at guaging inconspicuous issues too.The efficacy of his therapies is yet to be reviewed.Hoping for the best of everything and looking forward to have another healthy experience with his team
                                    </figure>
                                    <p className="font-weight-bold mb-0">Priya Kukreja</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="grid grid2">
                                    <img src="img/testimonial/woman.png" className="client-profile" />
                                    <i className="fas fa-quote-right font-2rem text-theme-color2 mb-0"></i>
                                    <figure className="font-14 text-black mb-4">
                                    Amazing, awesome Practitioner. I view his zeal for mental health at par with mine, and can give a review for him anyday happily. His empathy n passion certainly shines him out as a Psychologist.
                                    </figure>
                                    <p className="font-weight-bold mb-0">Nimisha Rastogi</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="grid grid2">
                                    <img src="img/testimonial/man.png" className="client-profile" />
                                    <i className="fas fa-quote-right font-2rem text-theme-color2 mb-0"></i>
                                    <figure className="font-14 text-black mb-4">
                                    I met him only twice or thrice, but when I met him very first day on 03 / 10/17, I feel satisfy and feel like yaaa KUCH TO BAAT HAI BANDE MEE... Imran sir is so nice to handle kids and specially
                                    </figure>
                                    <p className="font-weight-bold mb-0">Asim Mallick</p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    </div> 
{/* Testimonails end */}
        </div>
     );
}
 
export default Testimonials;