import moment from "moment";
import { Link } from "react-router-dom";

const Latest_news = ({ blogs }) => {
    return (
        <div id="blog">
            {/* Our Latest News */}
            <div className="section-gray" style={{ background: "#f7f7f7" }}>
                <div className="container">
                    <div className="col-md-7 mx-auto text-center mb-5">
                        <h3 className="caption font-weight-bold font-2rem mb-2 text-center">Our Blogs</h3>
                        <p className="font-15 text-dark text-center">We believe in sharing our expertise for the better of the Patients. Read our latest Blogs and learn more about Child Development Disorders and Disabilities.</p>
                    </div>
                    <div className="row">
                        {
                            blogs ? blogs?.map((blog, i) => {
                                return <div className="col-md-4" key={i}>
                                    <div className="service-box mb-4">
                                        <div className="service-img blog-img">
                                            <span className="blog-category">{blog?.category}</span>
                                            <img src={`data:image/jpeg;base64,${blog?.image}`} className="img-fluid" alt={blog?.title} />
                                        </div>
                                        <div className="services-content">
                                            <p className="">
                                                {/* 30 Sep, 2021 */}
                                                {moment(blog?.created_at).format("MMM DD, YYYY")}
                                            </p>
                                            <h5 className="mb-4 font-weight-bold">{blog?.title}</h5>
                                            <Link to={`/BlogDetails?id=${blog?.id}`} className="fw-normal h3 text-warning">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            }) : <div>We are writing something informative for you.</div>
                        }
                        {/* <div className="col-md-4 abt-imgt">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Mumbai</span>
                                <img src="img/blog/2.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 8 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">On Air - Psychology of Road rage </h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Chennai</span>
                                <img src="img/blog/3.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 1 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">Adolescence is a period of lot of emotional</h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div> */}
                        <div className="col-md-12 text-center mt-4">
                            <Link to="/Blogs" className="btn btn-theme-color" type="button">View All <i className="fas fa-arrow-right ml-2 fa-zoom-hover"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Our Latest News */}

        </div>
    );
}

export default Latest_news;