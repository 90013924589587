import OwlCarousel from "react-owl-carousel";

const Services_pp = ({ videos }) => {
    const owl_carousel_video = {
        margin: 20,
        loop: true,
        // navRewind: true,
        nav: true,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='fas fa-long-arrow-alt-left'></i>",
            "<i class='fas fa-long-arrow-alt-right'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 2
            }
        }
    };
    return (
        <div>
            {/* <!--pp services Section Start--> */}
            <div className="news-sec">
                <div className="section-white bg-transparent">
                    <div className="container">
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-md-6">
                                <p className="font-15 text-dark text-center">Videos</p>
                                <h3 className="caption font-weight-bold font-25 mb-4 text-black text-center">Patient Information Videos</h3>
                            </div>
                            <div className="col"></div>
                        </div>
                        <div className="mt-4">

                            <OwlCarousel className="owl-carousel owl-carousel-video owl-theme owl-loaded owl-drag mt-4" {...owl_carousel_video} style={{ marginTop: "1.5rem" }}>
                                {
                                    videos && videos?.filter((i) => i.thumbnails?.maxres?.url)?.map((video, i) => {
                                        return <div className="item animate__animated animate__fadeInUp" key={i}>
                                            <a href={`https://www.youtube.com/watch/${video?.videoId}`} target="_blank">
                                                <div className="grid">
                                                    <figure className="effect-marley">
                                                        <figcaption>
                                                            {/* <iframe width="100%" height="300" src={`https://www.youtube.com/embed/${video?.videoId}`} title={video?.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                                            <img src={video?.thumbnails?.maxres?.url} width="100%" height={"100%"} />
                                                            <h2> <span className="font-weight-bold font-15">{video?.title?.length > 50 ? video?.title?.slice(0, 50) + "..." : video?.title}</span> </h2>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </a>
                                        </div>
                                    })
                                }
                                {/* <div className="item animate__animated animate__fadeInUp">
                                    <div className="grid">
                                        <figure className="effect-marley">
                                            <figcaption>
                                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/eWg91ZBGTIA" title="HOW TO BE HAPPY IN PRESENT DIFFICULT SITUATIONS :-)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                <h2> <span className="font-weight-bold font-15">How to be happy in present difficult situations</span> </h2>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="item animate__animated animate__fadeInUp">
                                    <div className="grid">
                                        <figure className="effect-marley">
                                            <figcaption>
                                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/dBVDniAz0Yo" title="HOW TO MANAGE MOBILE ADDICTION IN CHILDREN- Recommended Guidelines For Screen Management in Children" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                <h2> <span className="font-weight-bold font-15">How to Manage Mobile Addiction in Children</span></h2>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                                <div className="item animate__animated animate__fadeInUp">
                                    <div className="grid">
                                        <figure className="effect-marley">
                                            <figcaption>
                                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/Q737UMpETAM" title="Parenting Adolescents and Behaviour Modification in Autism" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                <h2 className="text-left"> <span className="font-weight-bold font-15">Parenting Adolescents and Behaviour Modification in Autism</span></h2>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div> */}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--pp Services Section End--> */}
        </div>
    );
}

export default Services_pp;