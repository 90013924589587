import moment from "moment/moment";
import { Link } from "react-router-dom";

const Blogs = ({ blogs }) => {
    return (
        <div className="blogspage">

            <div className="section-parallax">
                <div className="parallax">
                    <div className="container">
                        <div className="col-md-6 mx-auto">
                            <h3 className="caption font-weight-bold font-25 mb-2 text-center">Our Blogs</h3>
                            <p className="text-center"><Link to="/" className="text-white"><u>Home</u></Link> / <span className="text-white">Blogs</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-gray" style={{ background: "#f7f7f7" }}>
                <div className="container">
                    <div className="row">
                        {blogs ? blogs?.map((blog) => {
                            return <div className="col-md-4">
                                <div className="service-box mb-4">
                                    <div className="service-img blog-img">
                                        <span className="blog-category">{blog?.category}</span>
                                        <img src={`data:image/jpeg;base64,${blog?.image}`} className="img-fluid" alt="" />
                                    </div>
                                    <div className="services-content">
                                        <p className="">
                                            {/* 30 Sep, 2021 */}
                                            {moment(blog?.created_at).format("MMM DD, YYYY")}
                                        </p>
                                        <h5 className="mb-4 font-weight-bold">{blog?.title}</h5>
                                        <Link to={`/BlogDetails?id=${blog?.id}`} className="fw-normal h3 text-warning">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        }) : <div>We are writing something informative for you.</div>}
                        {/* <div className="col-md-4 abt-imgt">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Mumbai</span>
                                <img src="img/blog/2.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 8 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">On Air - Psychology of Road rage </h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Chennai</span>
                                <img src="img/blog/3.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 1 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">Adolescence is a period of lot of emotional</h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Delhi</span>
                                <img src="img/blog/1.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 30 Sep, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">Successful Completion of Workshop on Autism</h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 abt-imgt">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Mumbai</span>
                                <img src="img/blog/2.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 8 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">On Air - Psychology of Road rage </h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="service-box mb-4">
                            <div className="service-img blog-img">
                                <span className="blog-category">Chennai</span>
                                <img src="img/blog/3.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="services-content">
                                <p className=""> 1 Jan, 2021 </p>
                                <h5 className="mb-4 font-weight-bold">Adolescence is a period of lot of emotional</h5>
                                <Link to="/BlogDetails" className="fw-normal h3 text-warning">Read More</Link>
                            </div>
                        </div>
                    </div>  */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;