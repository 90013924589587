import Slider from './Slider';
import About from './About';
import Services from './Services';
import Award from './Award';
import Why_choose_us from './Why_Choose_us';
import Services_pp from './Services_pp';
// import Team from './Team';
import Testimonials from './Testimonials';
// import Book_appointment from './Book_appointment';
import Subscribe_newslatter from './Subscribe_newslatter';
import Gallery from './Gallery';
import Latest_news from './Latest_news';

const Home = ({ videos, blogs }) => {
    return (
        <div className="Home-component">
            <Slider />
            <Services />
            <About />
            <Award />
            <Why_choose_us />
            <Services_pp videos={videos} />
            {/* <Team />  */}
            <Gallery />
            <Testimonials />
            {/* <Book_appointment />  */}
            <Latest_news blogs={blogs} />
            <Subscribe_newslatter />

        </div>
    );
}

export default Home;