const Why_choose_us = () => {
    return ( 
        <div> 
{/* why choose us start */}
<div className="section-parallax"> 
<div className="parallax" style={{background: "linear-gradient(45deg, #000000b0, #0000008c)"}}> 
            <div className="container">
                <div className="col-md-7 text-center mx-auto mb-md-5 mb-4">
                <h3 className="caption font-weight-bold font-25 mb-3">Why Choose Us</h3>
                        <p className="font-15">We are Science Directed, Data Driven, Multidisciplinary Expert Team of Professionals from varied backgrounds with A to Z solutions for Child Development and Mental Health.</p>
                </div>
                <div className="row">
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/1.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> Assessment for Autism Spectrum Disorder </span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Assessment Done By Our Multidisciplinary Team Of Professionals</div>
                            </div> 
                        </div>
                    </a>
                </div>
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/2.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> Neuro-Developmental Assessments </span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Assessment For Infants and Toddlers with Developmental Delays Done by Multidisciplinary Team Of Professionals</div>
                            </div> 
                        </div>
                    </a>
                </div>
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/3.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> Autism Interventions </span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Scientific Evidence Based Multidisciplinary Intervention Done By Team Of Professionals With Excellent Experience In Autism Treatment</div>
                            </div> 
                        </div>
                    </a>
                </div>
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/4.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> Assessment For Learning Disorder </span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Assessment Done by Multidisciplinary Team Of Professionals For Dyslexia/Dysgraphia/Dyscalculia</div>
                            </div> 
                        </div>
                    </a>
                </div>
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/5.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> High Risk Neonate Clinic </span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Early Assessments and Interventions High Risk Neonates By Multidisciplinary Team Of Professionals</div>
                            </div> 
                        </div>
                    </a>
                </div>
                <div className="col-md-4 d-flex">
                    <a href="#" className="grid gallery shadow">
                        <div className="">
                            <img src="img/why-us/6.png" className="service-icon" />
                            <h5 className="card-title mb-3">
                                <span className="font-15 font-weight-bold" title=""> Counseling & Behavior Modification Clinic</span>
                            </h5>
                            <div className="pb-4">
                                <div className="text-muted font-14">Behavior modification refers to the techniques used to try and decrease or increase a particular type of behavior or reaction.</div>
                            </div> 
                        </div>
                    </a>
                </div>
                </div>
            </div>
        </div>
    </div> 
{/* why choose us end */}
        </div>
     );
}
 
export default Why_choose_us;