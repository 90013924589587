import axios from "axios";
import { BASE_URL } from "../utils/api";


export const getToken = () => {
    return localStorage.getItem("token")
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem("user"))
}

// const token = getToken()

const config = {
    headers: {
        Authorization: `Bearer ${getToken()}`,
        Accept: "application/json",
    },
};
const controller = new AbortController();
const videos = BASE_URL + "/api/video/allVideos"
const endPoint = BASE_URL + "/api/doctor/alldoctors";
const productEndPoint = BASE_URL + "/api/product/allproducts"
const availableTimes = BASE_URL + "/api/doctor/availability/availableTimes/amount"
const createOrder = BASE_URL + "/api/order/create"
const loginUrl = BASE_URL + "/api/user/login"
const kidsByUserId = BASE_URL + "/api/allkids"
const register = BASE_URL + "/api/user/register"
const addKid = BASE_URL + "/api/addkid"
const getBlogs = BASE_URL + "/api/blogs/getAll"

export const login = (user) => {
    return axios.post(loginUrl, user, {
        headers: {
            Accept: "application/json",
        },
    }, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            controller.abort();
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const registerUser = (user) => {
    return axios.post(register, user, {
        headers: {
            Accept: "application/json",
        },
    }, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            controller.abort();
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const createKid = (kid) => {
    return axios.post(addKid, kid, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: "application/json",
        },
    }, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            controller.abort();
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getTherapistsHelper = () => {
    return axios.get(endPoint, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getBlogsHelper = () => {
    return axios.get(getBlogs, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getKidsByUserId = (conf) => {
    return axios.get(kidsByUserId, conf, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getProductsHelper = () => {
    return axios.get(productEndPoint, config, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getAvailableTimes = (params) => {
    return axios.post(availableTimes, params, config, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            controller.abort();
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const createOrderWithAmount = (params) => {
    return axios.post(createOrder, params, config, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            controller.abort();
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}

export const getVideosHelper = () => {
    return axios.get(videos, {
        signal: controller.signal,
    })
        .then(({ data }) => {
            return data
        })
        .catch((err) => {
            alert(err.response.data.detail)
            localStorage.clear()
        });
}


// FUNCTIONS

export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    // age = `${age} Years ${m} Months`
    return age;
};
