const Slider = () => {
    return (
        <div>

            {/* <!--Slider Section Start--> */}
            <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="1" className=""></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="2" className=""></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="3" className=""></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="4" className=""></li> 
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="image-slide">
                            <img src="img/banner3.jpg" className="img-fluid w-100" />
                        </div> 
                    </div>
                    <div className="carousel-item">
                        <div className="image-slide">
                            <img src="img/banner2.jpg" className="img-fluid w-100" />
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="image-slide">
                            <img src="img/banner1.jpg" className="img-fluid w-100" />
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="image-slide">
                            <img src="img/banner4.jpg" className="img-fluid w-100" />
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="image-slide">
                            <img src="img/banner5.jpg" className="img-fluid w-100" />
                        </div>
                    </div> 
                </div>
            </div>
            {/* <!--Slider Section End--> */}

        </div>
    );
}

export default Slider;