// import { useState } from "react";
// import { Link } from "react-router-dom";
// // import Book_appointment from "./Book_appointment";
// import Login_popup from "./Login_popup";

// const Header = () => {
//     const [open, setOpen] = useState(false);
//     const addClass = open ? 'visibile-sidebar' : 'sidebar-show';
//     const addBackdropClass = open ? 'sidebarclassadd' : '';

//     return (
//         <div> 
//                 {/* <!--upper header start--> */}
//                 <nav className="navbar navbar-expand-lg navbar-first px-4 d-md-block d-none" style={{background: "#6cc067"}}>
//             <div className="container">
             
//             <a className="font-weight-bold mr-4 font-12 text-white" href="tel:+919810129089"> <i className="fas fa-phone fa-rotate-90"></i> +91-9810129089 </a>
//             <a className="font-weight-bold font-12 text-white" href="mailto:info@mindmeadow.in"> <i className="fas fa-envelope"></i> info@mindmeadow.in</a>
//             <div id="" className="navbar-collapse"> 
//                 <form className="form-inline ml-auto justify-content-center">  
//                     <div>
//                         <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-instagram"></i></a>
//                         <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-facebook-square"></i></a>
//                         <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-twitter"></i></a>
//                     </div>
//                 </form>
//             </div>
//             </div>
//         </nav>
//                 {/* <!--upper header END--> */}

//                 {/* <!--2nd header start--> */}
//                 <nav className="navbar navbar-expand-lg navbar-first sticky-top bg-white py-3">
//                     <div className="container">
//                     <Link className="navbar-brand pb-0" to="/"> <img className="img-fluid" src="img/logo.png" width="160px" alt="logo" /> </Link>
//                     <button className="navbar-toggler navbar-toggler-right sidebar" type="button" onClick={() => setOpen(true)}>
//                         <i className="fas fa-bars text-theme-color2 font-2rem"></i>
//                     </button>
//                     <div id="ddmenu" className="display-none-media collapse navbar-collapse">
//                         <div className="navbar-nav mr-auto"></div>
//                         <form className="form-inline ml-auto">
//                             <Link className="nav-link active ml-0 font-14" to="/"> Home</Link>
//                             <a className="nav-link ml-0 font-14" href="#services"> Services</a>
//                             <Link className="nav-link ml-0 font-14" to="/Therapist"> Therapist</Link>
//                             <a className="nav-link ml-0 font-14" href="#aboutus"> About</a>
//                             <Link className="nav-link ml-0 font-14" to="/GalleryPage"> Gallery</Link>
//                             <Link className="nav-link ml-0 font-14" to="/Blogs"> Blog</Link>
//                             {/* <a className="nav-link ml-0 font-14" href=""> Contact</a> */}
//                             <div className="btn-group">
//                                 <button type="button" className="btn btn-black-color btn-block px-4" data-toggle="modal" data-target="#login-popup">Appointment Now</button>
//                                 {/* <!--<button type="button" className="btn btn-black-color dropdown-toggle btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Login / Signup
//                     </button>
//                         <div className="dropdown-menu dropdown-menu-right">
//                             <a className="dropdown-item pl-2 pr-1" href="#!">Log Out</a> 
//                         </div>--> */}
//                             </div>
//                         </form>
//                     </div>
//                     </div>
//                 </nav>
//                 {/* <!--2nd header END--> */}


//                 {/* <!--sidebar start--> */}
//                 <div className={`${addBackdropClass} sbac`} onClick={() => setOpen(false)}></div>
//                 <div className={`${addClass} sidebar-show`}>
//                     <div className="sliderbgclass">
//                         <button id="sidebar-close-btn" type="button" className="close" aria-label="Close" onClick={() => setOpen(false)}>
//                             <span aria-hidden="true" style={{ fontSize: "35px" }}>×</span>
//                         </button>
//                         <div className="border-bottom bg-theme-color">
//                             <img className="img-fluid pt-3 pb-2 pl-3" src="img/logo.png" style={{ marginBottom: "0.3rem", width: "198px", filter: "invert(1) grayscale(1) brightness(7.5)" }} alt="logo" />
//                         </div>

//                         <div className="row col-md-12 p-3 ml-0">
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Home</span></a>
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Service</span></a>
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Therapist</span></a>
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Blog </span></a>
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> About</span></a>
//                             <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Contact</span></a>
//                         </div>
//                         <form className="row col-md-12 p-3 ml-0 border-top">
//                             <div className="col-md-12 mt-4 btn-group">
//                                 <button type="button" className="btn btn-black-color btn-block" data-toggle="modal" data-target="#login-popup">Appointment Now</button>
//                                 {/* <!-- <button type="button" className="btn btn-black-color dropdown-toggle btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Login/Signup
//                     </button>
//                         <div className="dropdown-menu">
//                             <a className="dropdown-item pl-2 pr-1" href="#!">Log Out</a> 
//                         </div>--> */}
//                             </div>
//                         </form>
//                     </div>
//                 </div> 
//             {/* <!--sidebar end--> */} 

            
//     {/* <Book_appointment /> */}
//     <Login_popup />
//         </div>
//     );
// }

// export default Header;

import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import Book_appointment from "./Book_appointment";
import Login_popup from "./Login_popup";

const Header = () => {
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const addClass = open ? 'visibile-sidebar' : 'sidebar-show';
    const addBackdropClass = open ? 'sidebarclassadd' : '';
    const token = localStorage?.getItem("token")
    const [showPopUp, setShowPopUp] = useState(false)

    const handleAppointmentBooking = () => {
        if (token) {
            history.push("/Therapist")
            return
        }
        setShowPopUp(true)
    }

    return (
        <div>
            {/* <!--upper header start--> */}
            <nav className="navbar navbar-expand-lg navbar-first px-4 d-md-block d-none" style={{ background: "#6cc067" }}>
                <div className="container">

                    <a className="font-weight-bold mr-4 font-12 text-white" href="tel:+919810129089"> <i className="fas fa-phone fa-rotate-90"></i> +91-9810129089 </a>
                    <a className="font-weight-bold font-12 text-white" href="mailto:info@mindmeadow.in"> <i className="fas fa-envelope"></i> info@mindmeadow.in</a>
                    <div id="" className="navbar-collapse">
                        <form className="form-inline ml-auto justify-content-center">
                            <div>
                                <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-instagram"></i></a>
                                <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-facebook-square"></i></a>
                                <a href="/" target="_blank" className="mx-1"><i className="fab text-white px-2 fa-twitter"></i></a>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
            {/* <!--upper header END--> */}

            {/* <!--2nd header start--> */}
            <nav className="navbar navbar-expand-lg navbar-first sticky-top bg-white py-3">
                <div className="container">
                    <Link className="navbar-brand pb-0" to="/"> <img className="img-fluid" src="img/logo.png" width="160px" alt="logo" /> </Link>
                    <button className="navbar-toggler navbar-toggler-right sidebar" type="button" onClick={() => setOpen(true)}>
                        <i className="fas fa-bars text-theme-color2 font-2rem"></i>
                    </button>
                    <div id="ddmenu" className="display-none-media collapse navbar-collapse">
                        <div className="navbar-nav mr-auto"></div>
                        <form className="form-inline ml-auto">
                            <Link className="nav-link active ml-0 font-14" to="/"> Home</Link>
                            <a className="nav-link ml-0 font-14" href="#services"> Services</a>
                            {/* <Link className="nav-link ml-0 font-14" to="/Therapist"> Therapist</Link> */}
                            <a className="nav-link ml-0 font-14" href="#aboutus"> About</a>
                            <a className="nav-link ml-0 font-14" href="#gallery"> Gallery</a>
                            <a className="nav-link ml-0 font-14" href="#blog"> Blog</a>
                            {/* <a className="nav-link ml-0 font-14" href=""> Contact</a> */}
                            <div className="btn-group">
                                <button type="button" className="btn btn-black-color btn-block px-4" data-toggle="modal" data-target="#login-popup" onClick={handleAppointmentBooking}>Book Appointment</button>
                                {/* <!--<button type="button" className="btn btn-black-color dropdown-toggle btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Login / Signup
                    </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item pl-2 pr-1" href="#!">Log Out</a> 
                        </div>--> */}
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
            {/* <!--2nd header END--> */}


            {/* <!--sidebar start--> */}
            <div className={`${addBackdropClass} sbac`} onClick={() => setOpen(false)}></div>
            <div className={`${addClass} sidebar-show`}>
                <div className="sliderbgclass">
                    <button id="sidebar-close-btn" type="button" className="close" aria-label="Close" onClick={() => setOpen(false)}>
                        <span aria-hidden="true" style={{ fontSize: "35px" }}>×</span>
                    </button>
                    <div className="border-bottom bg-theme-color">
                        <img className="img-fluid pt-3 pb-2 pl-3" src="img/logo.png" style={{ marginBottom: "0.3rem", width: "198px", filter: "invert(1) grayscale(1) brightness(7.5)" }} alt="logo" />
                    </div>

                    <div className="row col-md-12 p-3 ml-0">
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Home</span></a>
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Service</span></a>
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Therapist</span></a>
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Blog </span></a>
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> About</span></a>
                        <a href="/" className="sidebar-icon mt-3 p-0 col-md-12"> <span className="gray-text"> Contact</span></a>
                    </div>
                    <form className="row col-md-12 p-3 ml-0 border-top">
                        <div className="col-md-12 mt-4 btn-group">
                            <button type="button" className="btn btn-black-color btn-block" data-toggle="modal" data-target="#login-popup">Appointment Now</button>
                            {/* <!-- <button type="button" className="btn btn-black-color dropdown-toggle btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Login/Signup
                    </button>
                        <div className="dropdown-menu">
                            <a className="dropdown-item pl-2 pr-1" href="#!">Log Out</a> 
                        </div>--> */}
                        </div>
                    </form>
                </div>
            </div>
            {/* <!--sidebar end--> */}


            {/* <Book_appointment /> */}
            {showPopUp && <Login_popup setShowPopUp={setShowPopUp} />}
        </div>
    );
}

export default Header;