import { Link } from "react-router-dom";

const Gallery = () => {
    return ( 
        <div id="gallery">
            
        
        {/* Our Gallery */}

        <div className="section-white pb-md-5 pb-0" style={{ backgroundImage: "url(img/testimonial-bg.png)", backgroundSize: "cover",backgroundAttachment:"fixed" }}>
            <div className="px-md-5 px-4">
                <div className="row mb-md-5 mb-4">
                    <div className="col-md-6 col-6">
                        <h3 className="caption font-weight-bold font-2rem mb-2 text-white">Our Gallery</h3>
                    </div>
                    <div className="col-md-6 col-6 text-right">
                    <Link to="/GalleryPage" className="btn btn-theme-color">View All <i className="fas fa-arrow-right ml-2 fa-zoom-hover"></i></Link>
                    </div>
                </div>
            </div>
            <div className="px-md-5 pb-md-4 pb-0">
                <div className="row m-0">
                    <div className="">
                        <div className="row no-gutters" id="lightgallery">
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/1.jpg">
                                <img src="img/gallery/1.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/2.jpg">
                                <img src="img/gallery/2.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/3.jpg">
                                <img src="img/gallery/3.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/4.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Our Gallery */}
        </div>
     );
}
 
export default Gallery;