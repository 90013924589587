import { Link } from "react-router-dom";

const GalleryPage = () => {
    return ( 
        <div className="gallerypage">
            <div className="section-parallax">
                <div className="parallax">
                    <div className="container">
                        <div className="col-md-6 mx-auto">
                            <h3 className="caption font-weight-bold font-25 mb-2 text-center">Our Gallery</h3>
                            <p className="text-center"><Link to="/" className="text-white"><u>Home</u></Link> / <span className="text-white">Gallery</span></p>
                        </div>
                    </div>
                </div>
            </div> 

        <div className="section-white pb-md-5 pb-0">
            <div className="px-md-5 pb-md-4 pb-0"> 
                    <div className="">
                        <div className="row no-gutters" id="lightgallery">
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/1.jpg">
                                <img src="img/gallery/1.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/2.jpg">
                                <img src="img/gallery/2.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/3.jpg">
                                <img src="img/gallery/3.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/4.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/5.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/6.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/7.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/8.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>  
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/9.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>  
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/10.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>  
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/11.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>  
                            <a className="col-md-3 col-6 gallery-box" href="img/gallery/4.jpg">
                                <img src="img/gallery/12.jpg" className="img-fluid w-100" alt='' />
                                <p><i className="fas fa-search-plus font-2rem"></i></p>
                            </a>  
                    </div>
                </div>
            </div>
        </div>

        </div>
     );
}
 
export default GalleryPage;