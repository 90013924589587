export const services = [
    {
        id: 9,
        name: "Occupational Therapy",
        price: "600",
        image: "img/services/Occupational-therapy-2.png",
        description: "Occupational Therapy",
    },
    {
        id: 10,
        name: "Counselling",
        price: "1000",
        image: "img/services/Counselling.png",
        description: "Counselling",
    },
    {
        id: 4,
        name: "Special Education",
        price: "600",
        image: "img/services/Special-education.png",
        description: "Special Education",
    },
    {
        id: 6,
        name: "Consultation",
        price: "2000",
        image: "img/services/consultation.png",
        description: "Consultation",
    },
    {
        id: 8,
        name: "Speech Therapy",
        price: "1",
        image: "img/services/speech-therapy.png",
        description: "Speech Therapy",
    }
]