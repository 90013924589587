import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const BlogDetails = ({ blogs }) => {

    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const id = search.get("id")

    const [blogById, setBlogById] = useState(null)

    useEffect(() => {
        setBlogById(blogs?.find((ele) => ele.id == id))
    }, [id])


    return (
        <div className="blogdetails">
            <div className="section-parallax">
                <div className="parallax">
                    <div className="container">
                        <div className="col-md-8">
                            <h3 className="caption font-weight-bold font-25 mb-2">{blogById?.title}</h3>
                            <p className=""><Link to="/" className="text-white"><u>Home</u></Link> / <Link to="/Blogs" className="text-white"><u>Our Blogs</u></Link> / <span className="text-white">{blogById?.title}</span></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section-gray">
                <div className="container">
                    <div className="row" style={{ marginTop: "-100px" }}>
                        <div className="col-md-12">
                            <div className="bg-white shadow border-radius-10" style={{ overflow: "hidden" }}>
                                <div className="">
                                    <div className="effect-marley border-0">
                                        <img className="img-fluid w-100" src={`data:image/jpeg;base64,${blogById?.image}`} alt={blogById?.title} />
                                    </div>
                                </div>
                                <div className="p-4 bg-white">
                                    <p className="text-theme-color mb-2 font-12 font-weight-bold">{moment(blogById?.created_at).format("MMM DD, YYYY")}</p>
                                    <h5 className="card-title mb-0">
                                        <p className="text-black font-20 font-weight-bold" title="">{blogById?.title}</p>
                                    </h5>
                                    <div className="py-1 mt-2">
                                        {/* <p className="text-muted font-14 caption"></p><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p> */}
                                        <p>{blogById?.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default BlogDetails;