import React, { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { Link, useHistory } from "react-router-dom";
import { createOrderWithAmount, getAvailableTimes, getKidsByUserId, getToken, getUser } from "./helper/helper";
import { displayRazorpay } from "./helper/razerPayHelper";

const BookTherapist = ({ products }) => {
    const user = getUser()
    const history = useHistory()
    const search = new URLSearchParams(location.search)
    const docId = search.get("id")
    const pid = search.get("pid")
    let [date, setDate] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState("")
    const [amount, setAmount] = useState(0)
    const [perSessionCost, setPerSessionCost] = useState(0)
    const [orderCreatedData, setOrderCreatedData] = useState(null)
    const [kidsList, setKidsList] = useState([])
    const [availableTimes, setAvailableTimes] = useState([])
    const [selectedTherapist, setSelectedTherapist] = useState(null)

    const config = {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: "application/json",
        },
    };

    useEffect(() => {
        getKidsByUserId(config).then((data) => {
            setKidsList(data)
        })
        if (pid) {
            setPerSessionCost(products?.find((item) => item.id == pid)?.price)
        }
        if (docId) {
            getAvailableTimes({
                docId: docId,
                dates: [],
                productId: pid
            }).then((data) => {
                setSelectedTherapist(data?.doctor)
            })
        }
        return () => {
            setOrderCreatedData(null)
        }
    }, [])

    useEffect(() => {
        if (docId) {
            getAvailableTimes({
                docId: docId,
                dates: date?.map((item) => `${item?.day > 9 ? item?.day : `0${item.day}`}-${item?.monthIndex + 1}-${String(item?.year)?.slice(-2)}`) || [],
                productId: pid
            }).then((data) => {
                // setSelectedTherapist(data?.doctor)
                setAvailableTimes(data?.timings)
                setAmount(data?.amount)
            })
        }
    }, [date])

    const handleSelectTiming = (e, time) => {
        let times = document.querySelectorAll(".timings")
        times?.forEach((ele) => {
            ele.classList.remove("selected")
            if (e.currentTarget.title == ele.title) {
                e.currentTarget.classList.add("selected")
                setSelectedSlot(time)
            }
        })
    }

    const handleCreateOrder = () => {
        createOrderWithAmount({
            name: products?.find((item) => item.id == pid)?.name,
            productId: products?.find((item) => item.id == pid)?.id,
            userId: user?.id,
            amount: amount,
        })
            .then((data) => {
                displayRazorpay({
                    user: user,
                    order: data?.Order,
                    productId: products?.find((item) => item.id == pid)?.id,
                    docId: docId,
                    patientId: selectedKid || "",
                    dates: date?.map((item) => `${item?.day > 9 ? item?.day : `0${item.day}`}-${item?.monthIndex + 1}-${String(item?.year)?.slice(-2)}`) || [],
                    time: selectedSlot
                })
                setOrderCreatedData(data?.Order)
            })
    }
    const [selectedKid, setSelectedKid] = useState("")
    const handleSelectKid = (e) => {
        setSelectedKid(e.target.value)
    }
    // RAZERPAY INTEGRATION
    // console.log("ORDER CREATED DATA::", orderCreatedData);
    // console.log("SELECTED KID", selectedKid);
    // RAZERPAY INTEGRATION

    return (
        <div>
            <div className="section-parallax">
                <div className="parallax">
                    <div className="container">
                        <div className="col-md-12 text-left">
                            {/* <h3 className="caption font-weight-bold font-25 mb-2">Pragati Goyal</h3>
                            <p className="caption">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</p>
                            <span className="badge badge-primary p-3 font-20">₹1200/Session</span> */}
                            <h3 className="caption font-weight-bold font-25 mb-2">{selectedTherapist?.name}</h3>
                            {/* <p className="caption">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</p> */}
                            <p className="caption">{selectedTherapist?.speciality}</p>
                            <span className="badge badge-success p-3 font-20">₹ {perSessionCost} / Session</span>

                            <p className="mt-4"><Link to="/Therapist" className="text-white"><u>Therapist</u></Link> / <span className="text-white">Book Appointment</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-gray">
                <div className="container">
                    <div className="col-md-8 mx-auto mb-5" style={{ marginTop: "-90px" }}>
                        <div className="bg-white w-100 shadow text-left p-3 border-radius-10" >
                            <div className="row">
                                <div className="col-md-5 align-self-center">
                                    <p className="mb-0 font-weight-bold text-dark">Select Child</p>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <select value={selectedKid} onChange={(e) => handleSelectKid(e)} className="form-control style-input border" id="exampleFormControlSelect1">
                                            <option value={""}>Select One</option>
                                            {kidsList?.map((kid, i) => {
                                                return <option key={i} id={kid?.id} value={kid?.id}>{kid?.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4 d-flex">
                            <div className="bg-white w-100 shadow text-left p-3 border-radius-10" >
                                <p className="mb-3 font-weight-bold text-dark">Choose Date</p>
                                <hr />
                                <Calendar minDate={new DateObject()} multiple value={date} format={"DD-MM-YY"} onChange={setDate} style={{ margin: "0 auto" }} />
                            </div>
                        </div>
                        <div className="col-md-4 d-flex ">
                            <div className="bg-white w-100 shadow p-3 border-radius-10">
                                <p className="mb-3 font-weight-bold text-dark">Choose Time</p>
                                <hr />
                                {
                                    availableTimes?.length > 0 ? availableTimes?.map((timing, i) => {
                                        return <span key={i} title={timing} className="btn btn-outline-secondary m-1 timings" onClick={(e) => { handleSelectTiming(e, timing) }}>{timing}</span>
                                    }) : <p>No Slots available</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="bg-white w-100 shadow text-left p-3 border-radius-10" >
                                <p className="mb-3 font-weight-bold text-dark">Proceed To Payment</p>
                                <table className="table border">
                                    <tbody>
                                        <tr>
                                            <td>Total: </td>
                                            <td className="text-right font-weight-bold">₹ {amount}</td>
                                        </tr>
                                        <tr>
                                            <td>GST:</td>
                                            <td className="text-right font-weight-bold">NA</td>
                                        </tr>
                                        <tr>
                                            <td>Discount:</td>
                                            <td className="text-right font-weight-bold">NA</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Total:</td>
                                            <td className="text-right font-weight-bold text-primary">₹ {amount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button disabled={selectedSlot == "" || selectedKid == "" || selectedKid == null} onClick={handleCreateOrder} className="btn btn-theme-color btn-block">Proceed To Payment <i className="fas fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookTherapist;


// import React, { useEffect, useState } from "react";
// import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
// import { Link, useLocation } from "react-router-dom";
// import { createOrderWithAmount, getAvailableTimes, getKidsByUserId, getUser } from "./helper/helper";
// import { displayRazorpay } from "./helper/razerPayHelper";

// const BookTherapist = ({ products }) => {
//     const user = getUser()
//     const location = useLocation()
//     const search = new URLSearchParams(location.search)
//     const docId = search.get("id")
//     const pid = search.get("pid")
//     let [date, setDate] = useState([]);
//     const [selectedSlot, setSelectedSlot] = useState("")
//     const [amount, setAmount] = useState(0)
//     const [perSessionCost, setPerSessionCost] = useState(0)
//     const [orderCreatedData, setOrderCreatedData] = useState(null)
//     const [kidsList, setKidsList] = useState([])
//     const [availableTimes, setAvailableTimes] = useState([])
//     const [selectedTherapist, setSelectedTherapist] = useState(null)
//     console.log(date);
//     useEffect(() => {
//         getKidsByUserId().then((data) => {
//             setKidsList(data)
//         })
//         if (pid) {
//             setPerSessionCost(products?.find((item) => item.id == pid)?.price)
//         }
//         if (docId) {
//             getAvailableTimes({
//                 docId: docId,
//                 dates: [],
//                 productId: pid
//             }).then((data) => {
//                 setSelectedTherapist(data?.doctor)
//             })
//         }
//         return () => {
//             setOrderCreatedData(null)
//         }
//     }, [])

//     useEffect(() => {
//         if (docId) {
//             getAvailableTimes({
//                 docId: docId,
//                 dates: date?.map((item) => `${item?.day > 9 ? item?.day : `0${item.day}`}-${item?.monthIndex + 1}-${String(item?.year)?.slice(-2)}`) || [],
//                 productId: pid
//             }).then((data) => {
//                 // setSelectedTherapist(data?.doctor)
//                 setAvailableTimes(data?.timings)
//                 setAmount(data?.amount)
//             })
//         }
//     }, [date])

//     const handleSelectTiming = (e, time) => {
//         let times = document.querySelectorAll(".timings")
//         times?.forEach((ele) => {
//             ele.classList.remove("selected")
//             if (e.currentTarget.title == ele.title) {
//                 e.currentTarget.classList.add("selected")
//                 setSelectedSlot(time)
//             }
//         })
//     }

//     const handleCreateOrder = () => {
//         createOrderWithAmount({
//             name: products?.find((item) => item.id == pid)?.name,
//             productId: products?.find((item) => item.id == pid)?.id,
//             userId: user?.id,
//             amount: amount,
//         })
//             .then((data) => {
//                 displayRazorpay({
//                     user: user,
//                     order: data?.Order,
//                     productId: products?.find((item) => item.id == pid)?.id,
//                     docId: docId,
//                     patientId: selectedKid || "",
//                     dates: date?.map((item) => `${item?.day > 9 ? item?.day : `0${item.day}`}-${item?.monthIndex + 1}-${String(item?.year)?.slice(-2)}`) || [],
//                     time: selectedSlot
//                 })
//                 setOrderCreatedData(data?.Order)
//             })
//     }
//     const [selectedKid, setSelectedKid] = useState("")
//     const handleSelectKid = (e) => {
//         setSelectedKid(e.target.value)
//     }
//     // RAZERPAY INTEGRATION
//     console.log("ORDER CREATED DATA::", orderCreatedData);
//     console.log("SELECTED KID", selectedKid);
//     // RAZERPAY INTEGRATION

//     return (
//         <div>
//             <div className="section-parallax">
//                 <div className="parallax">
//                     <div className="container d-flex">
//                         <div className="col-md-6 text-left">
//                             <h3 className="caption font-weight-bold font-25 mb-2">{selectedTherapist?.name}</h3>
//                             {/* <p className="caption">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</p> */}
//                             <span className="badge badge-success p-3 font-20">₹ {perSessionCost} / Session</span>
//                             <p className="mt-4"><Link to="/" className="text-white"><u>Therapist</u></Link> / <span className="text-white">View Details</span></p>
//                         </div>
//                         <div className="col-md-6 text-left">
//                             <h3 className="caption font-weight-bold font-25 mb-2">{"Select Kid"}</h3>
//                             {/* <p className="caption">Lead Clinical Psychologist, MPhil, RCI Registered, RML Hospital</p> */}
//                             <select value={selectedKid} onChange={(e) => handleSelectKid(e)}>
//                                 <option value={""}>Select One</option>
//                                 {kidsList?.map((kid, i) => {
//                                     return <option key={i} id={kid?.id} value={kid?.id}>{kid?.name}</option>
//                                 })}
//                             </select>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="section-gray">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-4 d-flex">
//                             <div className="bg-white w-100 shadow text-left p-3 border-radius-10" >
//                                 <p className="mb-3 font-weight-bold text-dark">Choose Date</p>
//                                 <hr />
//                                 <Calendar minDate={new DateObject()} multiple value={date} format={"DD-MM-YY"} onChange={setDate} style={{ margin: "0 auto" }} />
//                             </div>
//                         </div>
//                         <div className="col-md-4 d-flex ">
//                             <div className="bg-white w-100 shadow p-3 border-radius-10">
//                                 <p className="mb-3 font-weight-bold text-dark">Choose Time</p>
//                                 <hr />
//                                 {
//                                     availableTimes?.length > 0 ? availableTimes?.map((timing, i) => {
//                                         return <span key={i} title={timing} className="btn btn-outline-secondary m-1 timings" onClick={(e) => { handleSelectTiming(e, timing) }}>{timing}</span>
//                                     }) : <p>No Slots available</p>
//                                 }

//                             </div>
//                         </div>
//                         <div className="col-md-4 d-flex">
//                             <div className="bg-white w-100 shadow text-left p-3 border-radius-10" >
//                                 <p className="mb-3 font-weight-bold text-dark" >Proceed To Payment</p>
//                                 <table className="table border">
//                                     <tbody>
//                                         <tr>
//                                             <td>Total: </td>
//                                             <td className="text-right font-weight-bold">₹ {amount}</td>
//                                         </tr>
//                                         <tr>
//                                             <td>GST:</td>
//                                             <td className="text-right font-weight-bold">NA</td>
//                                         </tr>
//                                         <tr>
//                                             <td>Discount:</td>
//                                             <td className="text-right font-weight-bold">NA</td>
//                                         </tr>
//                                         <tr>
//                                             <td className="font-weight-bold">Total:</td>
//                                             <td className="text-right font-weight-bold text-primary">₹ {amount}</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                                 <button disabled={selectedSlot == "" || selectedKid == "" || selectedKid == null} onClick={handleCreateOrder} className="btn btn-theme-color btn-block">Proceed To Payment <i className="fas fa-arrow-right"></i></button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default BookTherapist;