import OwlCarousel from "react-owl-carousel";
import { services } from "./utils/data";

const Services = () => {
    
    const owl_carousel_services = {
        margin: 20,
        loop: true,
        // navRewind: true,
        nav: true,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='fas fa-long-arrow-alt-left'></i>",
            "<i class='fas fa-long-arrow-alt-right'></i>"
        ],
        responsive: {
            0: {
                items: 1.5
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    };
    return (
        <div id="services">
            {/* Services start */}

            <div className="section-white service-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-md-6">
                            <h3 className="caption font-weight-bold font-25 mb-2 text-black text-center">Our Top Services</h3>
                            <p className="font-15 text-dark text-center mb-0">Child Development Disorders and Disabilities.</p>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="mt-5 animate__animated animate__fadeInUp">
                        <OwlCarousel className="owl-carousel owl-carousel-services owl-theme owl-loaded owl-drag" {...owl_carousel_services}>
                            {
                                services?.map((service, i) => {
                                    return <div className="item animate__animated animate__fadeInUp" key={i} id={service?.id}>
                                        <a className="grid gallery">
                                            <div className="">
                                                <img src={service?.image} className="service-icon" />
                                                <h5 className="card-title mb-3">
                                                    <span className="font-15 font-weight-bold" title=""> {service?.name} </span>
                                                </h5>
                                                <div className="pb-4">
                                                    <div className="text-muted font-14">{service?.description}</div>
                                                </div>
                                                {/* <span className="btn btn-theme-color btn-block">Read More</span> */}
                                            </div>
                                        </a>
                                    </div>
                                    //  <div className="col-md-3" key={i} id={service?.id}>
                                    //     <a className="grid gallery">
                                    //         <div className="">
                                    //             <img src="img/service1.png" className="service-icon" />
                                    //             <h5 className="card-title mb-3">
                                    //                 <span className="font-15 font-weight-bold" title=""> {service?.name} </span>
                                    //             </h5>
                                    //             <div className="pb-4">
                                    //                 <div className="text-muted font-14">{service?.description}</div>
                                    //             </div>
                                    //             {/* <span className="btn btn-theme-color btn-block">Read More</span> */}
                                    //         </div>
                                    //     </a>
                                    // </div>
                                })
                            }

                            {/* <div className="item animate__animated animate__fadeInUp">
                                <a href="#" className="grid gallery">
                                    <div className="">
                                        <img src="img/service2.png" className="service-icon" />
                                        <h5 className="card-title mb-3">
                                            <span className="font-15 font-weight-bold" title=""> Early Intervention, Diagnosis, Follow Up </span>
                                        </h5>
                                        <div className="pb-4">
                                            <div className="text-muted font-14">For Autism, ADHD, Down Syndrome, Cerebral Palsy, Learning Disability...</div>
                                        </div>
                                        <span className="btn btn-theme-color btn-block">Read More</span>
                                    </div>
                                </a>
                            </div>
                            <div className="item animate__animated animate__fadeInUp" >
                                <a href="#" className="grid gallery">
                                    <div className="">
                                        <img src="img/service3.png" className="service-icon" />
                                        <h5 className="card-title mb-3">
                                            <span className="font-15 font-weight-bold" title=""> Assessments By The Team Of Professionals </span>
                                        </h5>
                                        <div className="pb-4">
                                            <div className="text-muted font-14">Neurodevelopmental, Psychological And Behavioral Assessments Viz...</div>
                                        </div>
                                        <span className="btn btn-theme-color btn-block">Read More</span>
                                    </div>
                                </a>
                            </div>
                            <div className="item animate__animated animate__fadeInUp">
                                <a href="#" className="grid gallery">
                                    <div className="">
                                        <img src="img/service4.png" className="service-icon" />
                                        <h5 className="card-title mb-3">
                                            <span className="font-15 font-weight-bold" title=""> Multidisciplinary Approach To Management </span>
                                        </h5>
                                        <div className="pb-4">
                                            <div className="text-muted font-14">By Developmental Pediatrician,Child Psychologist with a brigade...</div>
                                        </div>
                                        <span className="btn btn-theme-color btn-block">Read More</span>
                                    </div>
                                </a>
                            </div>
                            <div className="item animate__animated animate__fadeInUp">
                                <a href="#" className="grid gallery">
                                    <div className="">
                                        <img src="img/service2.png" className="service-icon" />
                                        <h5 className="card-title mb-3">
                                            <span className="font-15 font-weight-bold" title=""> Multidisciplinary Approach To Management </span>
                                        </h5>
                                        <div className="pb-4">
                                            <div className="text-muted font-14">By Developmental Pediatrician,Child Psychologist with a brigade...</div>
                                        </div>
                                        <span className="btn btn-theme-color btn-block">Read More</span>
                                    </div>
                                </a>
                            </div> */}
                        </OwlCarousel>


                    </div>
                </div>
            </div>

            {/* Services end */}
        </div>
    );
}

export default Services;