import { useState } from "react"
import { useHistory } from "react-router-dom"
import { createKid, getAge, login, registerUser } from "./helper/helper"

const Login_popup = ({ setShowPopUp }) => {
    const navigate = useHistory()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [forgetPasswordBool, setForgetPasswordBool] = useState(false)
    const [openKidForm, setOpenKidForm] = useState(false)
    const [registerBool, setRegisterBool] = useState(false)
    const [loginBool, setLoginBool] = useState(true)
    const [loginCreds, setLoginCreds] = useState({
        email: "",
        password: ""
    })
    const [registrationData, setRegistrationData] = useState({
        name: "",
        email: "",
        username: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
        password: "",
        is_staff: false,
        is_superuser: false,
        is_active: true,
        mobileNumber: "",
        avatar: "some Base64 Image",
        position: "U",
    })

    const [kidInfo, setKidInfo] = useState({
        parentid: "",
        name: "",
        dob: "",
        avatar: "NA",
        motherName: "",
        fatherName: "",
        address: "NA",
        userRelation: "",
        gender: "",
        age: "",
        // informant: "",
        // consultationType: ""
    });

    const handleForgetPasswordShow = () => {
        setForgetPasswordBool(true)
        setRegisterBool(false)
        setLoginBool(false)
    }
    const handleLoginShow = () => {
        setForgetPasswordBool(false)
        setRegisterBool(false)
        setLoginBool(true)
    }

    const handleRegisterShow = () => {
        setForgetPasswordBool(false)
        setRegisterBool(true)
        setLoginBool(false)
    }
    const handleKidFormShow = () => {
        setForgetPasswordBool(false)
        setRegisterBool(false)
        setLoginBool(false)
        setOpenKidForm(true)
    }

    const handleClose = () => {
        document.querySelector(".modal-backdrop").remove()
        document.body.style.overflow = "scroll"
        setShowPopUp(false)
        setForgetPasswordBool(false)
        setRegisterBool(false)
        setLoginBool(true)
        setLoginCreds({
            email: "",
            password: ""
        })
    }

    const handleChangeLoginForm = (name) => (e) => {
        setLoginCreds({ ...loginCreds, [name]: e.target.value })
    }

    const handleChangeRegisterForm = (name) => (e) => {
        setRegistrationData({ ...registrationData, [name]: e.target.value })
    }

    const handleChangeKidForm = (name) => (e) => {
        setKidInfo({ ...kidInfo, [name]: e.target.value })
    }

    const handleSignIn = () => {
        setLoading(true)
        if (loginCreds?.email && loginCreds?.password) {
            login(loginCreds).then((data) => {
                if (data) {
                    handleClose()
                    localStorage?.setItem("token", data?.token)
                    localStorage?.setItem("user", JSON.stringify(data?.user))
                    setLoading(false)
                    setUser(data)
                    setLoginCreds({
                        email: "",
                        password: ""
                    })
                    navigate.push("/Therapist")
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }


    const handleRegisterUser = () => {
        registerUser(registrationData).then((data) => {
            if (data?.err) {
                alert(data?.err);
                return;
            }
            if (data?.id) {
                setKidInfo({
                    ...kidInfo,
                    parentid: data?.id,
                    // fatherName:
                    //     kidInfo?.userRelation === "Father" && parentInfo?.name
                    //         ? parentInfo?.name
                    //         : kidInfo?.fatherName,
                    // motherName:
                    //     kidInfo?.userRelation === "Mother" && parentInfo?.name
                    //         ? parentInfo?.name
                    //         : kidInfo?.motherName,
                })
                login({
                    email: registrationData?.email,
                    password: registrationData?.password,
                }).then((data) => {
                    if (data) {
                        // handleClose()
                        localStorage?.setItem("token", data?.token)
                        localStorage?.setItem("user", JSON.stringify(data?.user))
                        // setLoading(false)
                        setUser(data)
                        setLoginCreds({
                            email: "",
                            password: ""
                        })
                        handleKidFormShow()
                    }
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleCreateKid = () => {
        createKid({ ...kidInfo, age: getAge(kidInfo?.dob) }).then((data) => {
            if (data?.err) {
                alert(data?.err);
                return;
            }
            if (data?.id) {
                alert("User created successfully.")
                handleClose()
                navigate.push("/Therapist")
            }
        })
    }

    return (
        <div>
            {/* <!--popup-START--> */}
            <div className="modal fade bd-example-modal-lg pr-0" id="login-popup" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="row no-gutters">
                            <div className="col-md-6 d-md-flex d-none">
                                <div className="logo-popup w-100">
                                    <img src="img/logo.png" alt="logo" />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex" style={{ flexDirection: "column" }}>
                                <div className="modal-header pt-3 pb-2 mb-3" style={{ zIndex: "10" }}>
                                    <h3 className="caption text-black font-weight-bolder font-20 text-left mb-2">
                                        {loginBool && <span className="login-content">Login</span>}
                                        {registerBool && <span className="signup-content">Register</span>}
                                        {openKidForm && <span className="signup-content">Kid Information</span>}
                                        {forgetPasswordBool && <span className="forgot-content">Forgot Password</span>}
                                    </h3>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="row card-row-set">
                                    <div className="col-md-12">

                                        {/* Login form start */}

                                        {loginBool && <form id="loginform" className="py-3 login-content">
                                            <div className="">
                                                <div className="text-center">
                                                    <div className="jsom-message-alert"></div>
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control style-input border" name="email" type="email" placeholder="Your Email" value={loginCreds?.email} onChange={handleChangeLoginForm("email")} />
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control style-input border" name="email" type="password" placeholder="Password" value={loginCreds?.password} onChange={handleChangeLoginForm("password")} />
                                                </div>
                                                <button type="button" className="btn btn-black-color btn-block text-black style-input mb-3" onClick={handleSignIn}>Log In</button>

                                                <a className="float-right text-dark font-weight-bold" id="register-btn" onClick={handleRegisterShow}>Register</a>
                                                {/* <a className="text-dark font-weight-bold" id="forgot-btn" href="javascript:void(0);">Forget Password</a> */}
                                                <a className="text-dark font-weight-bold" id="forgot-btn" onClick={handleForgetPasswordShow}>Forget Password</a>
                                            </div>
                                        </form>}
                                        {/* Login form end */}

                                        {/* forgot form start */}
                                        {forgetPasswordBool && <form id="forgotform" className="py-3 forgot-content" method="post">
                                            <div className="">
                                                <div className="text-center">
                                                    <div className="jsom-message-alert"></div>
                                                </div>
                                                <div className="form-group">
                                                    <input className="form-control style-input border" name="email" type="email" placeholder="Your Email" id="example-email-input" />
                                                </div>
                                                <button type="submit" className="btn btn-black-color btn-block text-black style-input mb-3">Get New Password</button>
                                            </div>
                                        </form>}
                                        {/* forgot form end */}

                                        {/* Register form start */}

                                        {registerBool && <form className="py-3 signup-content" id="formregister" name="formregister">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control style-input border" placeholder="Name" value={registrationData?.name} onChange={handleChangeRegisterForm("name")} />
                                            </div>

                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control style-input border" placeholder="Email Address" value={registrationData?.email} onChange={handleChangeRegisterForm("email")} />
                                            </div>

                                            <div className="form-group">
                                                <input type="text" name="mobileNumber" className="form-control style-input border" placeholder="Mobile No" value={registrationData?.mobileNumber} onChange={handleChangeRegisterForm("mobileNumber")} />
                                            </div>

                                            <div className="form-group">
                                                <input type="password" name="password" id="registerpassword" className="form-control style-input border" placeholder="Password" value={registrationData?.password} onChange={handleChangeRegisterForm("password")} />
                                            </div>
                                            <div className="form-group">
                                                {/* <input type="password" name="confirmPassword" className="form-control style-input border" placeholder="Confirm Password" /> */}
                                            </div>

                                            <div className="form-group" id="registerdiv">
                                                <button type="button" className="btn btn-black-color btn-block text-black style-input" onClick={handleRegisterUser}>Register</button>
                                            </div>

                                            <p className="font-14 text-muted">By registering your details, you agree with our Terms &amp; Conditions , and Privacy and Cookie Policy.</p>
                                            <hr />
                                            <a className="text-dark font-weight-bold" id="login-btn" onClick={handleLoginShow}>Already have an account? | Login</a>
                                        </form>}

                                        {/* Register form end */}
                                        {/* KID FORM */}
                                        {openKidForm && <form className="py-3 signup-content" id="formregister" name="formregister">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control style-input border" placeholder="Kid Name" value={kidInfo?.name} onChange={handleChangeKidForm("name")} />
                                            </div>

                                            <div className="form-group">
                                                <input type="date" name="dob" className="form-control style-input border" placeholder="Date Of Birth" value={kidInfo?.dob} onChange={handleChangeKidForm("dob")} />
                                            </div>

                                            <div className="form-group">
                                                {/* <input type="text" name="mobileNumber" className="form-control style-input border" placeholder="Mobile No" value={kidInfo?.gender} onChange={handleChangeKidForm("gender")} /> */}
                                                <select
                                                    autoComplete="on"
                                                    className="form-control style-input border"
                                                    type="text"
                                                    placeholder="Gender"
                                                    name="gender"
                                                    value={kidInfo?.gender}
                                                    onChange={handleChangeKidForm("gender")}
                                                >
                                                    <option value="">Gender</option>
                                                    <option value="M">Male</option>
                                                    <option value="F">Female</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                {/* <input type="password" name="password" id="registerpassword" className="form-control style-input border" placeholder="Password" value={registrationData?.password} onChange={handleChangeKidForm("password")} /> */}
                                                <select
                                                    autoComplete="on"
                                                    className="form-control style-input border"
                                                    type="text"
                                                    placeholder="Relation"
                                                    name="userRelation"
                                                    value={kidInfo?.userRelation}
                                                    onChange={handleChangeKidForm("userRelation")}
                                                >
                                                    <option value="">Relation</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    {/* <option value="Guardian">Guardian</option> */}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                {/* <input type="password" name="confirmPassword" className="form-control style-input border" placeholder="Confirm Password" /> */}
                                                <input
                                                    autoComplete="on"
                                                    className="form-control style-input border"
                                                    type="text"
                                                    placeholder="Father's Name"
                                                    name="fatherName"
                                                    value={
                                                        // kidInfo?.userRelation === "Father" && parentInfo?.name
                                                        //     ? parentInfo?.name
                                                        //     : 
                                                        kidInfo?.fatherName
                                                    }
                                                    onChange={handleChangeKidForm("fatherName")}
                                                />
                                            </div>
                                            <div className="form-group">
                                                {/* <input type="password" name="confirmPassword" className="form-control style-input border" placeholder="Confirm Password" /> */}
                                                <input
                                                    autoComplete="on"
                                                    className="form-control style-input border"
                                                    type="text"
                                                    placeholder="Mother's Name"
                                                    name="motherName"
                                                    value={
                                                        // kidInfo?.userRelation === "Mother" && parentInfo?.name
                                                        //     ? parentInfo?.name
                                                        //     :
                                                        kidInfo?.motherName
                                                    }
                                                    onChange={handleChangeKidForm("motherName")}
                                                />
                                            </div>
                                            <div className="form-group" style={{ display: "none" }}>
                                                {/* <input type="password" name="confirmPassword" className="form-control style-input border" placeholder="Confirm Password" /> */}
                                                <input
                                                    autoComplete="on"
                                                    type="text"
                                                    className="form-control style-input border"
                                                    placeholder="Age"
                                                    value={kidInfo?.age}
                                                    onChange={handleChangeKidForm("age")}
                                                />
                                            </div>
                                            <div className="form-group">
                                                {/* <input type="password" name="confirmPassword" className="form-control style-input border" placeholder="Confirm Password" /> */}
                                                <textarea
                                                    className="form-control style-input border"
                                                    name="address"
                                                    placeholder="Enter Address"
                                                    cols="30"
                                                    rows="3"
                                                    onChange={handleChangeKidForm("address")}
                                                ></textarea>
                                            </div>

                                            <div className="form-group" id="registerdiv">
                                                <button type="button" className="btn btn-black-color btn-block text-black style-input" onClick={handleCreateKid}>Add Kid</button>
                                            </div>

                                            <p className="font-14 text-muted">By registering your details, you agree with our Terms &amp; Conditions , and Privacy and Cookie Policy.</p>
                                            <hr />
                                            <a className="text-dark font-weight-bold" id="login-btn" onClick={handleLoginShow}>Already have an account? | Login</a>
                                        </form>}
                                        {/* KID FORM */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--popup-END--> */}
        </div>
    );
}

export default Login_popup;