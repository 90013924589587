
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import ScrollToTop from './ScrollToTop';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Therapist from './Therapist';
import BookTherapist from './BookTherapist';
import GalleryPage from './GalleryPage';
import Blogs from './Blogs';
import BlogDetails from './BlogDetails';
import { useEffect, useState } from 'react';
import { getBlogsHelper, getProductsHelper, getTherapistsHelper, getVideosHelper } from './helper/helper';

const App = () => {
    const [therapists, setTherapists] = useState(null)
    const [products, setProducts] = useState(null)
    const [videos, setVideos] = useState(null)
    const [blogs, setBlogs] = useState(null)
    useEffect(() => {
        getTherapistsHelper().then((data) => {
            setTherapists(data)
        })
        getProductsHelper().then((data) => {
            setProducts(data)
        })
        getVideosHelper().then((data) => {
            setVideos(data)
        })
        getBlogsHelper().then((data) => {
            console.log(data?.blogs);
            setBlogs(data?.blogs)
        })
    }, [])
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <Header />
                <div className="content">
                    <Switch>
                        <Route exact path="/">
                            <Home services={products} videos={videos} blogs={blogs} />
                        </Route>
                        <Route path="/therapist">
                            <Therapist therapists={therapists} products={products} />
                        </Route>
                        <Route path="/GalleryPage">
                            <GalleryPage />
                        </Route>
                        <Route path="/Blogs">
                            <Blogs blogs={blogs} />
                        </Route>
                        <Route path="/BlogDetails">
                            <BlogDetails blogs={blogs} />
                        </Route>
                        <Route path="/BookTherapist">
                            <BookTherapist products={products} />
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;