const About = () => {
    return ( 
        <div id="aboutus"> 
{/* who we are start */}
<div className="section-white pt-0">
        <div className="">
            <div className="container">
                <div className="row">  
                <div className="col-md-6">
                        <img src="img/abt.jpg" className="img-fluid border-radius-10 w-100" alt=""/>
                    </div>
                    <div className="col-md-6 align-self-center mb-md-0 mb-4">
                        <p className="font-2rem font-weight-bold mb-2 text-black">Mind Meadow</p>
                        <h3 className="caption font-weight-bold font-15 mb-4  text-theme-color">About Us</h3>
                        <p className="font-15 text-dark">Mind Meadow is a Multidisciplinary Center For Child Development & Mental Health Services.We are the first Phygital center to provide both offline and virtual services in the form of complete parent training modules.We provide early intervention and stimulation to children with developmental disorders like autism, adhd , learning disability (dyslexia, dysgraphia, dyscalculia), behavioural disorders like adhd, conduct disorder,oppositional defiant disorder, developmental delays, motor and other disorders of development through our expert team of professionals.We specialise in providing holistic management for mental health by providing expert care and couseling to children and adolescents with psychological , emotional , social or other behavioural or developmental problems.</p>
                        {/* <p className="font-15 text-dark">Mind Meadow- it gets better is a multidisciplinary center of excellence for child development & mental health. Mind meadow is the first Phygital centre of India which focuses on neurodevelopmental disorders and provides expert multidisciplinary developmental & mental health services  from birth through adolescence to adulthood.Mind meadow is a leader in autism interventions in india and is catering to more than 50 countries globally.</p> */}
                        {/* <p className="font-15 text-dark">Most Trusted Name For Management Of Neuro-developmental Disorders especially Autism, Learning & Behavioural Disorders. </p> */}
                        {/* <p className="my-4"><img src="img/signature.png" style={{width: "320px"}}></img></p> */}
                        {/* <span className="btn btn-black-color bg-secondary mr-3">See More</span> */}
                        {/* <span className="btn btn-theme-color">Make An Appointment</span> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
{/* who we are end */}
        </div>
     );
}
 
export default About;